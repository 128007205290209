import { useState, useEffect, ChangeEvent } from 'react';

import CardList from './components/card-list/card-list.component';
import SearchBox from './components/search-box/search-box.component';

import { getData } from './utils/data.utils';

import './App.css';

export type Monster = {
	id: string;
	name: string;
	email: string;
};

const App = () => {
	const [search, setSearch] = useState('');
	const [monsters, setMonsters] = useState<Monster[]>([]);
	const [filteredMonsters, setFilteredMonsters] = useState(monsters);

	useEffect(() => {
		const fetchUsers = async () => {
			const users = await getData<Monster[]>('https://jsonplaceholder.typicode.com/users');
			setMonsters(users);
		};

		fetchUsers();
	}, []);

	useEffect(() => {
		const newFilteredMonsters = monsters.filter((monster) => {
			return monster.name.toLocaleLowerCase().includes(search);
		});

		setFilteredMonsters(newFilteredMonsters);
	}, [search, monsters]);

	const onSearchChange = (event: ChangeEvent<HTMLInputElement>): void => {
		const searchTerm = event.target.value.toLocaleLowerCase();
		setSearch(searchTerm);
	};

	return (
		<div className='App'>
			<h1 className='app-title'>Monster Rolodex</h1>
			<SearchBox className='monsters-search-box' placeholder='Search Monsters' onChangeHandler={onSearchChange} />
			<CardList monsters={filteredMonsters} />
		</div>
	);
};

// class App extends Component {
// 	constructor() {
// 		super();

// 		this.state = {
// 			monsters: [],
// 			search: ''
// 		};
// 	}

// 	componentDidMount() {
// 		fetch('https://jsonplaceholder.typicode.com/users')
// 			.then((response) => response.json())
// 			.then((users) => this.setState(() => {
// 				return { monsters: users };
// 			})
// 		);
// 	}

// 	onSearchChange = (event) => {
// 		const searchTerm = event.target.value.toLocaleLowerCase();
// 		this.setState({ search: searchTerm });
// 	};

// 	render() {
// 		const { monsters, search } = this.state;
// 		const { onSearchChange } = this;

// 		const filteredMonsters = monsters.filter((monster) => {
// 			return monster.name.toLocaleLowerCase().includes(search);
// 		});

// 		return (
// 			<div className="App">
// 				<h1 className='app-title'>Monster Rolodex</h1>
// 				<SearchBox className='monsters-search-box' placeholder='Search Monsters' onChangeHandler={onSearchChange} />
// 				<CardList monsters={filteredMonsters} />
// 			</div>
// 		);
// 	}
// }

export default App;
